import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { taskInfoSelector } from '~/gql/task/task';

export const calendarSelector = Selector('Calendar')({
  checked: true,
  date: true,
  duration: true,
  id: true,
  label: true,
  retroChecked: true,
  selectible: true,
});
export const taskInfoForPlanningSelector = Selector('TaskInfoForPlanning')({
  ...taskInfoSelector,
  calendar: calendarSelector,
});

export const planningSelector = Selector('Planning')({
  tasks: taskInfoForPlanningSelector,
  tasksSupp: taskInfoForPlanningSelector,
  calendar: calendarSelector,
});

export const devisProdSelector = Selector('DevisForProjectProd')({
  id: true,
  type: true,
  signedDate: true,
  tasks: taskInfoSelector,
});

export const taskPlanningInfoSelector = Selector('TaskPlanningInfo')({
  id: true,
  date: true,
  duration: true,
  taskId: true,
});

export const GQL_CREATE_OR_UPDATE_TASK_PLANNING = typedGql('mutation')({
  createOrUpdateTaskPlanning: [
    {
      updateTaskPlanningInput: {
        projectId: $('projectId', 'Int!'),
        taskId: $('taskId', 'Int!'),
        dates: $('dates', '[UpdateDatePlanningInput!]!'),
      },
    },
    devisProdSelector,
  ],
});

export const GQL_UPDATE_TASK_PLANNING = typedGql('mutation')({
  updateTaskPlanning: [
    {
      updateTaskCheckedPlanningInput: {
        taskId: $('taskId', 'Int!'),
        dates: $('dates', '[UpdateDateCheckPlanningInput!]!'),
      },
    },
    devisProdSelector,
  ],
});

export const GQL_GET_PLANNING_BY_PROJECT_ID = typedGql('query')({
  getPlanningByProjectId: [
    {
      id: $('id', 'Int!'),
    },
    planningSelector,
  ],
});

export const GQL_GET_PERIOD_PLANNING_BY_START_DATE = typedGql('query')({
  getPeriodTaskPlanningByStartDate: [
    {
      filterDatesTaskPlanningInput: $(
        'filterDatesTaskPlanningInput',
        'FilterDatesTaskPlanningInput!'
      ),
    },
    planningSelector,
  ],
});

export const GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID = typedGql('query')({
  getPeriodTaskPlanningByProjectId: [
    {
      filterTaskPlanningInput: $(
        'filterTaskPlanningInput',
        'FilterTaskPlanningInput!'
      ),
    },
    planningSelector,
  ],
});

export type StoredTaskPlanningInfo = InputType<
  GraphQLTypes['TaskPlanningInfo'],
  typeof taskPlanningInfoSelector
>;

export type StoredPlanning = InputType<
  GraphQLTypes['Planning'],
  typeof planningSelector
>;

export type StoredTaskInfoForPlanning = InputType<
  GraphQLTypes['TaskInfoForPlanning'],
  typeof taskInfoForPlanningSelector
>;

export type StoredCalendar = InputType<
  GraphQLTypes['Calendar'],
  typeof calendarSelector
>;
