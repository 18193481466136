import { styled } from 'styled-components';
import { classic } from '~/themes/classic';

export const SDivLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 70px;
  margin-top: 20px;
  width: 100%;

  img {
    height: 70px;
    object-fit: contain;
    width: 70px;
  }
`;

export const TableElement = styled.p`
  display: flex;
  color: ${classic.token?.colorPrimary};
`;

// -------------- Table Renta
export const SRentaTable = styled.table<{ width?: string }>`
  border-collapse: collapse;

  width: ${({ width }) => (width ? width : '100%')};
`;

export const SRentaTableHead = styled.thead<{ $borderTop?: boolean }>`
  background-color: #0b446f0a;
  border-bottom: 1px solid #0b446f4d;
  border-top: ${({ $borderTop }) =>
    $borderTop ? '1px solid #0b446f4d' : 'none'};
  user-select: none;
`;
export const SRentaTr = styled.tr<{ $firstBorderTop?: boolean }>`
  border-bottom: 1px solid #0b446f4d;
  border-top: ${({ $firstBorderTop }) =>
    $firstBorderTop
      ? `1px solid ${classic.token?.colorFillSecondary}`
      : 'none'};
`;

export const SRentaTh = styled.th<{
  $width?: string;
  $borderRight?: boolean;
  $borderLeft?: boolean;
  color?: string;
}>`
  border-right: ${({ $borderRight }) =>
    $borderRight ? '2px solid #0B446F80' : '1px solid #0B446F4D'};
  border-left: ${({ $borderLeft }) =>
    $borderLeft ? '1px solid #0B446F80' : 'none'};
  color: ${({ color }) => (color ? color : `${classic.token?.colorText}`)};
  padding: 5px;
  width: ${({ $width }) => ($width ? $width : 'auto')};
`;

export const SRentaTd = styled.td<{
  $width?: string;
  $align?: string;
  $bold?: boolean;
  $borderRight?: boolean;
  $borderLeft?: boolean;
  color?: string;
}>`
  border-right: ${({ $borderRight }) =>
    $borderRight ? '2px solid #0B446F80' : '1px solid #0B446F4D'};
  border-left: ${({ $borderLeft }) =>
    $borderLeft ? '1px solid #0B446F80' : 'none'};
  color: ${({ color }) => (color ? color : `${classic.token?.colorText}`)};

  padding: 5px 10px;
  width: ${({ $width }) => ($width ? $width : 'auto')};
  text-align: ${({ $align }) => ($align ? $align : 'left')};
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`;

// -------------- table Devis ---------------
export const STable = styled.table<{ $margin?: string; $width?: string }>`
  background-color: #f5f5f5;
  margin: ${({ $margin }) => ($margin ? $margin : '10px 0')};
  width: ${({ $width }) => ($width ? $width : '100%')};
`;

export const SDevisTable = styled.table<{ $margin?: string; $width?: string }>`
  background-color: #f5f5f5;
  margin: ${({ $margin }) => ($margin ? $margin : '10px 0')};
  width: ${({ $width }) => ($width ? $width : '100%')};
  & > tbody td:first-child {
    z-index: 1;
    position: sticky;
    left: 0;
    background-color: #f5f5f5;
  }

  & > thead tr:first-child th {
    z-index: 2;
    position: sticky;
    top: 0;
  }

  & > thead tr:first-child th:first-child {
    z-index: 3;
    position: sticky;
    top: 0;
    left: 0;
  }

  & > tfoot tr:first-child th {
    z-index: 4;
    position: sticky;
    top: 0;
    background-color: #ebebeb;
  }
  & > tfoot tr th:first-child {
    background-color: #ebebeb;
    z-index: 5;
    position: sticky;
    top: 0;
    left: 0;
  }
`;

export const SDivTableItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;
export const SDivStickyItem = styled.div<{ $margin?: string }>`
  width: 50%;
  overflow: hidden;
  height: 100%;
  padding-left: 5px;
  margin: ${({ $margin }) => ($margin ? $margin : '0')};
`;

export const SDevisTh = styled.th<{
  $minWidth?: string;
  $textAlign?: string;
  $fontSize?: string;
  $padding?: string;
  $height?: string;
  $boldInputNumber?: boolean;
  $bg?: string;
}>`
  background-color: ${({ $bg }) => ($bg ? $bg : '#ebebeb')};
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '14px')};
  padding: ${({ $padding }) => ($padding ? $padding : '10px')};
  height: ${({ $height }) => ($height ? $height : 'auto')};
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : '0')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  user-select: none;
  position: relative;

  & > div > div > div > div > input {
    font-weight: ${({ $boldInputNumber }) =>
      $boldInputNumber ? 'bold' : 'normal'};
  }
`;

export const STaskDaysTh = styled.th<{
  $minWidth?: string;
  $textAlign?: string;
  $width?: string;
  $height?: string;
}>`
  padding: 5px;
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : '0')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  min-width: 38px;
  user-select: none;
  height: ${({ $height }) => ($height ? $height : 'auto')};
  max-width: ${({ $width }) => ($width ? $width : 'auto')};
  position: relative;
`;

export const SDevisTd = styled.td<{
  $textAlign?: string;
  $padding?: string;
  $minWidth?: string;
  $minHeight?: string;
  $height?: string;
}>`
  min-height: ${({ $minHeight }) => ($minHeight ? $minHeight : '42px')};
  height: ${({ $height }) => ($height ? $height : '42px')};
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : '0')};
  padding: ${({ $padding }) => ($padding ? $padding : '0 0')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  user-select: none;
  position: relative;
`;

export const STimeTd = styled.td<{
  $textAlign?: string;
  $padding?: string;
  $bg?: string;
}>`
  background-color: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  min-height: 32px;
  height: 32px;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  padding: ${({ $padding }) => ($padding ? $padding : '0 0')};
  user-select: none;
`;
export const SAdminTimeTd = styled.td<{ $width?: string }>`
  background-color: #fff;
  color: ${({ color }) => (color ? color : `${classic.token?.colorText}`)};
  width: ${({ $width }) => ($width ? $width : 'auto')};
  min-width: 270px;
`;

export const STableTr = styled.tr`
  &:hover td {
    background-color: #ebedf4cc;
  }
`;

export const STaskTd = styled.td<{
  $textAlign?: string;
  $width?: string;
  $height?: string;
  $padding?: string;
}>`
  // border: 0.5px solid #4a4a4a;
  background-color: #fff;
  min-height: 42px;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  position: relative;
  user-select: none;
  height: ${({ $height }) => ($height ? $height : '42px')};
  padding: ${({ $padding }) => ($padding ? $padding : '0 0')};
  width: ${({ $width }) => ($width ? $width : 'auto')};

  &.end {
    border: 1px solid gray;
    border-radius: 4px;
  }
  &.period {
    border: 1px solid green;
    border-radius: 4px;
  }
  &.notselectible {
    background-color: #e6abb6;
    border-radius: 4px;
    pointer-events: none;
  }
`;

export const SDevisTableHead = styled.thead`
  background-color: rgba(0, 0, 0, 0.04);
  user-select: none;
  position: relative;
`;

export const SDevisTableFoot = styled.tfoot`
  background-color: rgba(0, 0, 0, 0.04);
`;

export const SDivBtnAddRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

export const STr = styled.tr`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const STrDevisRow = styled.tr`
  position: relative;
`;

// -------------- fin table Devis ---------------

//--------------- div ----------------------------
export const SDivBtn = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;

export const SDivCheckRetro = styled.div`
  background-color: #88bde5;
  border-radius: 4px;
  bottom: 0;
  height: 60%;
  left: 0;
  position: absolute;
  width: 60%;
  &.client {
    background-color: #cad85f;
  }
`;

export const SDivCheckRetroInPl = styled.div`
  border: 1px solid #88bde5;
  border-radius: 4px;
  bottom: 0;
  height: 60%;
  left: 0;
  position: absolute;
  width: 60%;
`;

export const SDivCheckPlanning = styled.div`
  background-color: #82cb82;
  border-radius: 4px;
  top: 0;
  height: 80%;
  right: 0;
  position: absolute;
  width: 80%;
  &.client {
    background-color: #cad85f;
  }
`;

// #ba667580
export const SSpanPlanning = styled.span<{
  $opaque?: boolean;
  $fontWeight?: string;
  $padding?: string;
  $fontStyle?: string;
}>`
  color: ${({ $opaque }) => ($opaque ? `transparent` : `#9a4655`)};
  font-size: 12px;
  font-family: monospace;
  padding: 0 10px;
  font-style: ${({ $fontStyle }) => ($fontStyle ? $fontStyle : 'normal')};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'normal')};
  padding: ${({ $padding }) => ($padding ? $padding : '0 10px')};
`;

export const SSpanTime = styled.span<{
  $opaque?: boolean;
  $fontWeight?: string;
  $fontStyle?: string;
}>`
  color: ${({ $opaque }) => ($opaque ? `#0b446f80` : `#0b446f`)};
  font-size: 12px;
  font-family: monospace;
  font-style: ${({ $fontStyle }) => ($fontStyle ? $fontStyle : 'normal')};
  padding: 0 10px;
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'normal')};
`;

// Renta Items

export const SDivTaskItem = styled.div<{ $bg?: string }>`
  align-items: center;
  display: flex;

  border-bottom: 1px solid ${classic.token?.colorFillSecondary};
  background-color: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  justify-content: space-between;
  width: calc(100% - 1px);

  &:hover {
    background-color: ${classic.token?.colorFillAlter};
  }
`;

export const SDivTaskItemWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const SDivTaskName = styled.div<{
  fontWeight?: string;
  $align?: string;
  $bg?: string;
}>`
  align-items: center;
  display: flex;
  background-color: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  justify-content: ${({ $align }) => ($align ? $align : 'flex-end')};
  flex: 0 0 40%;
  border-left: 1px solid ${classic.token?.colorFillSecondary};
  border-right: 1px solid ${classic.token?.colorFillSecondary};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  padding: 5px 10px;
`;

export const SDivTaskSommeItem = styled.div<{
  fontWeight?: string;
  $align?: string;
  $bg?: string;
  color?: string;
  $borderRight?: boolean;
  $borderLeft?: boolean;
}>`
  align-items: center;
  background-color: ${({ $bg }) => ($bg ? $bg : 'transparent')};
  display: flex;
  color: ${({ color }) => (color ? color : `${classic.token?.colorText}`)};
  justify-content: ${({ $align }) => ($align ? $align : 'flex-end')};
  flex: 0 0 20%;
  height: 100%;
  padding: 5px 10px;
  border-right: ${({ $borderRight }) =>
    $borderRight ? `1px solid #0B446F80` : `1px solid #0B446F4D`};
  border-left: ${({ $borderLeft }) =>
    $borderLeft ? `1px solid #0B446F80` : `none`};
  /* border-left: ${({ $borderLeft }) =>
    $borderLeft ? `1px solid #0B446F4D` : `1px solid transparent`}; */
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
`;

export const STableContainer = styled.div`
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;

export const SDivRentaItem = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const SRentaSpan = styled.span<{ color?: string }>`
  color: ${({ color }) => (color ? color : `${classic.token?.colorText}`)};
`;
