import { GraphQLTypes, InputType, Selector, $ } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';
import { usersInfoForDevisSelector } from '~/gql/users/users';

export const taskForUserInfoSelector = Selector('TaskForUserInfo')({
  id: true,
  name: true,
  order: true,
  allTime: true,
  allUserPlanning: true,
  projectManagerFirstName: true,
  projectManagerLastName: true,
  projectName: true,
  projectType: true,
  time: {
    date: true,
    duration: true,
    comment: true,
  },
  userPlanning: {
    date: true,
    duration: true,
  },
  timeAndUserPlanning: {
    date: true,
    userPlanningDuration: true,
    timeDuration: true,
  },
});

export const taskInfoSelector = Selector('TaskInfoForDevis')({
  id: true,
  name: true,
  functionType: true,
  amount: true,
  amountSpend: true,
  duration: true,
  devisInitId: true,
  order: true,
  projectId: true,
  projectType: true,
  projectName: true,
  startDateInit: true,
  durationDays: true,
  durationClientDays: true,
  visibleInRetro: true,
  supp: true,
  calculatedAsAmount: true,
  posteId: true,
  taskPlanning: {
    id: true,
    date: true,
    duration: true,
  },
  user: usersInfoForDevisSelector,
});

export const taskShortInfoSelector = Selector('TaskShortInfo')({
  id: true,
  name: true,
  order: true,
  projectId: true,
  functionType: true,
  user: usersInfoForDevisSelector,
});

export const devisProdSelector = Selector('DevisForProjectProd')({
  id: true,
  type: true,
  signedDate: true,
  tasks: taskInfoSelector,
});

export const GQL_UPDATE_TASK_ORDER = typedGql('mutation')({
  updateTaskOrder: [
    {
      updateTaskOrderInput: $(
        'updateTaskOrderInput',
        '[UpdateTaskOrderInput!]!'
      ),
    },
    devisProdSelector,
  ],
});

export const GQL_UPDATE_TASK_SUPP = typedGql('mutation')({
  updateTaskSupp: [
    {
      updateTaskSuppInput: $('updateTaskSuppInput', 'UpdateTaskSuppInput!'),
    },
    devisProdSelector,
  ],
});

export const GQL_CREATE_TASK_SUPP = typedGql('mutation')({
  createTaskSupp: [
    {
      createTaskSuppInput: $('createTaskSuppInput', 'CreateTaskSuppInput!'),
    },
    devisProdSelector,
  ],
});

export const GQL_CREATE_TASK_INTERN = typedGql('mutation')({
  createTaskIntern: [
    {
      createTaskInternInput: $(
        'createTaskInternInput',
        'CreateTaskInternInput!'
      ),
    },
    true,
  ],
});

export const GQL_DELETE_TASK = typedGql('mutation')({
  deleteTask: [
    {
      id: $('id', 'Int!'),
    },
    devisProdSelector,
  ],
});

export const GQL_DELETE_TASK_INTERN = typedGql('mutation')({
  deleteTaskIntern: [
    {
      id: $('id', 'Int!'),
    },
    true,
  ],
});

export const GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE = typedGql('mutation')(
  {
    createOrUpdateUsersInTaskByFunctionType: [
      {
        createOrUpdateUsersByFunctionTypeInput: {
          projectId: $('projectId', 'Int!'),
          usersByFunctionType: $(
            'usersByFunctionType',
            '[UsersByFunctionTypeInput!]!'
          ),
        },
      },
      devisProdSelector,
    ],
  }
);

// export const GQL_IS_USERS_CAN_BE_CHANGED = typedGql('query')({
//   isUsersCanBeChangedInTask: [
//     {
//       createOrUpdateUsersInTaskInput: {
//         id: $('id', 'Int!'),
//         users: $('users', '[Int!]!'),
//       },
//     },
//     true,
//   ],
// });

export const GQL_CREATE_OR_UPDATE_USERS_IN_TASK = typedGql('mutation')({
  createOrUpdateUsersInTask: [
    {
      createOrUpdateUsersInTaskInput: {
        id: $('id', 'Int!'),
        users: $('users', '[Int!]!'),
      },
    },
    devisProdSelector,
  ],
});

export const GQL_CREATE_OR_UPDATE_USERS_IN_TASK_INTERN = typedGql('mutation')({
  createOrUpdateUsersInTaskIntern: [
    {
      createOrUpdateUsersInTaskInput: {
        id: $('id', 'Int!'),
        users: $('users', '[Int!]!'),
      },
    },
    devisProdSelector,
  ],
});

export const GQL_UPDATE_TASK_RETRO = typedGql('mutation')({
  updateTaskRetro: [
    {
      updateTaskRetroInput: {
        id: $('id', 'Int!'),
        durationDays: $('durationDays', 'Int!'),
        startDateInit: $('startDateInit', 'Int!'),
      },
    },
    devisProdSelector,
  ],
});

export type StoredTaskInfo = InputType<
  GraphQLTypes['TaskInfoForDevis'],
  typeof taskInfoSelector
>;

export const GQL_GET_TASKS_BY_PROJECT_ID = typedGql('query')({
  getTaskListByProjectId: [
    {
      id: $('id', 'Int!'),
    },
    taskShortInfoSelector,
  ],
});

export const GQL_TASKS_INFO_BY_USER_ID = typedGql('query')({
  getTasksInfoByUserId: [
    {
      id: $('id', 'Int!'),
    },
    taskForUserInfoSelector,
  ],
});

export const GQL_GET_TASKS_INTERN_BY_PROJECT_ID = typedGql('query')({
  getTasksInternByProjectId: [
    {
      id: $('id', 'Int!'),
    },
    taskShortInfoSelector,
  ],
});

export type StoredTaskShortInfo = InputType<
  GraphQLTypes['TaskShortInfo'],
  typeof taskShortInfoSelector
>;

export type StoredTaskForUserInfo = InputType<
  GraphQLTypes['TaskForUserInfo'],
  typeof taskForUserInfoSelector
>;
