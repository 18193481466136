import { useLazyQuery, useMutation } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Radio,
  Space,
  RadioChangeEvent,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState, useEffect } from 'react';
import { GQL_FUNCTIONS, StoredFunction } from '~/gql/function/function';
import { GQL_CREATE_TASK_SUPP } from '~/gql/task/task';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import DurationItem from '~/pages/components/ui/DurationItem';

import ModalCustom from '~/pages/components/ui/ModalCustom';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  projectId: number;
  title: string;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function AddTaskSupp({ title, isOpen, confirm, close, projectId }: IProps) {
  const { i18n } = useLingui();

  const { setCurrentDevisProd } = useDataUserStore();
  const formRef = React.useRef<FormInstance>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [functionTypes, setFunctionTypes] = useState<StoredFunction[]>([]);
  const [selectedFunctionType, setSelectedFunctionType] = useState<
    number | null
  >(null);
  const [taskName, setTaskName] = useState<string>('');

  const [amount, setAmount] = useState<number>(0);
  const [amountSpend, setAmountSpend] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [calcAsAmount, setCalcAsAmount] = useState(1);
  const [createTaskSupp] = useMutation(GQL_CREATE_TASK_SUPP, {
    client: authClient,
  });

  const [getFunctions] = useLazyQuery(GQL_FUNCTIONS, {
    client: authClient,
    onCompleted: (data) => {
      setFunctionTypes(data.getFunctions);
    },
  });

  useEffect(() => {
    formRef.current?.resetFields();
    void getFunctionTypes();
    setAmount(0);
    setAmountSpend(0);
    setDuration(0);
    setTaskName('');
    setSelectedFunctionType(null);
  }, []);

  async function getFunctionTypes() {
    setLoading(true);
    await getFunctions();
    setLoading(false);
  }

  async function submit() {
    const fType = functionTypes.find((el) => el.id === selectedFunctionType);

    if (!fType || !taskName) return;
    const createTaskSuppInput = {
      duration: duration,
      amount: amount,
      amountSpend: amountSpend,
      functionType: fType.name as FunctionType,
      projectId: projectId,
      name: taskName,
      supp: true,
    };
    const { data: _data } = await createTaskSupp({
      variables: {
        createTaskSuppInput,
      },
    });
    if (_data?.createTaskSupp) {
      setCurrentDevisProd({
        ..._data.createTaskSupp,
        tasks: _data.createTaskSupp.tasks?.sort(compareByOrder),
      });
      confirm();
    } else {
      close();
    }
    setDuration(0);
    formRef.current?.resetFields();
  }

  const onChangeAmount = (value: number | null) => {
    if (value !== null) setAmount(value);
  };

  const onChangeDuration = (_duration: number) => {
    setDuration(_duration);
  };

  const onChangeAmountSpend = (value: number | null) => {
    if (value !== null) setAmountSpend(value);
  };

  const onChange = (e: RadioChangeEvent) => {
    setCalcAsAmount(e.target.value);
  };

  return (
    <ModalCustom
      confirmDisabled={
        !taskName || !selectedFunctionType || !(duration || amount)
      }
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={() => {
        formRef.current?.resetFields();
        close();
      }}
    >
      {loading ? (
        <p>{i18n._('common.loading')} ...</p>
      ) : (
        <>
          <Form
            {...layout}
            ref={formRef}
            name="task-add"
            initialValues={{
              taskName: '',
              amount: 0,
              amountSpend: 0,
              duration: 0,
            }}
            style={{ maxWidth: 600 }}
          >
            <Radio.Group onChange={onChange} value={calcAsAmount}>
              <Space direction="vertical">
                <Radio value={1}>Durée</Radio>
                <Radio value={2}>Somme</Radio>
              </Space>
            </Radio.Group>
            <Form.Item
              name="taskName"
              label={i18n._('common.task.name')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.task-name.placeholder'),
                },
              ]}
            >
              <Input
                placeholder={i18n._('form.task-name.placeholder')}
                onChange={(e) => setTaskName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="functionType"
              label={i18n._('common.function.type')}
              rules={[
                {
                  required: true,
                  message: i18n._('form.task-name.placeholder'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={i18n._('common.function.type')}
                value={selectedFunctionType}
                onChange={setSelectedFunctionType}
                optionFilterProp="label"
                options={functionTypes.map((item) => ({
                  value: item.id,
                  label: i18n._(item.name),
                }))}
              />
            </Form.Item>

            {calcAsAmount === 1 && (
              <>
                <Form.Item
                  name="duration"
                  style={{ width: '100%' }}
                  label={i18n._('common.duration')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <DurationItem
                    $allowCorrection
                    duration={duration}
                    onChange={onChangeDuration}
                    $align="flex-start"
                  />
                </Form.Item>
              </>
            )}

            {calcAsAmount === 2 && (
              <>
                <Form.Item
                  name="amount"
                  style={{ width: '100%' }}
                  label={i18n._('common.amount')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '150px', marginRight: '10px' }}
                    placeholder={i18n._('form.amount.placeholder')}
                    onChange={onChangeAmount}
                    value={amount}
                    controls={false}
                    step="0.01"
                    addonAfter="€"
                  />
                </Form.Item>
                <Form.Item
                  name="amountSpend"
                  style={{ width: '100%' }}
                  label={i18n._('common.amount.spend')}
                  rules={[
                    {
                      required: true,
                      message: i18n._('form.project-name.placeholder'),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '150px', marginRight: '10px' }}
                    placeholder={i18n._('form.amount.placeholder')}
                    onChange={onChangeAmountSpend}
                    value={amountSpend}
                    controls={false}
                    step="0.01"
                    addonAfter="€"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
    </ModalCustom>
  );
}

export default AddTaskSupp;
