import { useMutation, useLazyQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { Form, Divider, Select } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useState, useEffect } from 'react';

import {
  GQL_CREATE_OR_UPDATE_USERS_IN_TASK,
  GQL_CREATE_OR_UPDATE_USERS_IN_TASK_INTERN,
} from '~/gql/task/task';
import {
  StoredUsers,
  GQL_USERS,
  StoredUsersInfoForDevis,
} from '~/gql/users/users';

import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import { getUsersListWithoutUsersWithTime } from '~/pages/components/common/utils-table/utils';

import ModalCustom from '~/pages/components/ui/ModalCustom';
import { compareByOrder } from '~/utils/utils/utils';

type IProps = {
  title: string;
  isOpen: boolean;
  confirm: (
    taskId: number,
    users: StoredUsersInfoForDevis[] | undefined,
    isInternTask: boolean
  ) => void;
  close: () => void;
  taskId: number;
  taskType?: 'INTERN';
  users: StoredUsersInfoForDevis[] | undefined;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function EditUsersInTask({
  title,
  isOpen,
  confirm,
  users,
  taskId,
  taskType,
  close,
}: IProps) {
  const { i18n } = useLingui();
  const { setCurrentDevisProd } = useDataUserStore();

  const [userList, setUserList] = useState<StoredUsers[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [usersWithTimes, setUsersWithTimes] = useState<number[]>([]);

  const formRef = React.useRef<FormInstance>(null);

  const [createOrUpdateUsersInTask] = useMutation(
    GQL_CREATE_OR_UPDATE_USERS_IN_TASK,
    {
      client: authClient,
    }
  );

  const [createOrUpdateUsersInTaskIntern] = useMutation(
    GQL_CREATE_OR_UPDATE_USERS_IN_TASK_INTERN,
    {
      client: authClient,
    }
  );

  const [getAllUsers] = useLazyQuery(GQL_USERS, {
    client: authClient,
    onCompleted: (data) => {
      // supprimer de cette liste les user qui ne peuvent pas être changés
      setUserList(data.getUsers);
    },
  });

  async function getUserList() {
    await getAllUsers();
  }

  useEffect(() => {
    setSelectedUsers([]);
    formRef.current?.resetFields();
    if (users) {
      const _usersWithOutTimes = [...users]
        .filter((el) => !el.hasTimes)
        .map((el) => el.id);

      setSelectedUsers([..._usersWithOutTimes]);

      const _usersWhithTimes = [...users]
        .filter((el) => el.hasTimes)
        .map((u) => u.id);
      setUsersWithTimes(_usersWhithTimes);
    } else {
      setSelectedUsers([]);
      setUsersWithTimes([]);
    }
  }, [users]);

  useEffect(() => {
    formRef.current?.resetFields();
    void getUserList();
  }, []);

  async function submit() {
    if (taskType === 'INTERN') {
      const { data: _data } = await createOrUpdateUsersInTaskIntern({
        variables: {
          id: taskId,
          users: selectedUsers.concat(usersWithTimes),
        },
      });
      if (_data) {
        confirm(
          taskId,
          _data.createOrUpdateUsersInTaskIntern.tasks?.find(
            (t) => t.id === taskId
          )?.user,
          true
        );
      } else {
        close();
      }
    } else {
      const { data: _data } = await createOrUpdateUsersInTask({
        variables: {
          id: taskId,
          users: selectedUsers.concat(usersWithTimes),
        },
      });

      if (_data) {
        setCurrentDevisProd({
          ..._data.createOrUpdateUsersInTask,
          tasks: _data.createOrUpdateUsersInTask.tasks?.sort(compareByOrder),
        });
        confirm(
          taskId,
          _data.createOrUpdateUsersInTask.tasks?.find((t) => t.id === taskId)
            ?.user,
          false
        );
      } else {
        close();
      }
      formRef.current?.resetFields();
    }
  }

  return (
    <ModalCustom
      title={title}
      isOpen={isOpen}
      confirm={submit}
      close={() => {
        close();
        formRef.current?.resetFields();
      }}
    >
      <Form
        {...layout}
        ref={formRef}
        name="task-edit-user"
        initialValues={{
          users: users
            ? [...users.filter((el) => !el.hasTimes).map((u) => u.id)]
            : [],
          usersWithTime: users
            ? [...users.filter((el) => el.hasTimes).map((u) => u.id)]
            : [],
        }}
        style={{ maxWidth: 600 }}
      >
        <Form.Item name="users" label={i18n._('form.edit.users')}>
          <Select
            mode="multiple"
            showSearch
            placeholder={i18n._('form.edit.users.placeholder')}
            value={selectedUsers}
            onChange={setSelectedUsers}
            style={{ width: '100%' }}
            optionFilterProp="label"
            options={getUsersListWithoutUsersWithTime(
              userList,
              usersWithTimes
            ).map((item) => ({
              value: item.id,
              label: item.firstName + ' ' + item.lastName,
            }))}
          />
        </Form.Item>

        <Divider />
        {usersWithTimes.length > 0 && (
          <>
            <span>{i18n._('users.edit.warning')}</span>
            <Select
              mode="multiple"
              showSearch
              disabled
              placeholder={i18n._('form.edit.users.placeholder')}
              value={usersWithTimes}
              style={{ width: '100%' }}
              optionFilterProp="label"
              options={userList.map((item) => ({
                value: item.id,
                label: item.firstName + ' ' + item.lastName,
              }))}
            />
          </>
        )}
      </Form>
    </ModalCustom>
  );
}

export default EditUsersInTask;
