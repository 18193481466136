import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useLingui, Trans } from '@lingui/react';
import { FunctionType } from '@zeus/index';
import { Button, Tooltip } from 'antd';

import { useEffect, useRef, useState } from 'react';
//import { exportComponentAsJPEG } from 'react-component-export-image';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE } from '~/gql/task/task';
import {
  GQL_CREATE_OR_UPDATE_TASK_PLANNING,
  GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID,
  GQL_GET_PERIOD_PLANNING_BY_START_DATE,
  GQL_UPDATE_TASK_PLANNING,
  StoredPlanning,
  StoredTaskInfoForPlanning,
} from '~/gql/task-planning/task-planning';
import { StoredUsersInfoForDevis } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import useDataUserStore from '~/helpers/store/data-user/data-user';
import AddTaskSupp from '~/pages/components/common/planning/AddTaskSupp';
import DeleteTaskSupp from '~/pages/components/common/planning/DeleteTaskSupp';
import EditAllUsers from '~/pages/components/common/planning/EditAllUsers';
import EditTaskSupp from '~/pages/components/common/planning/EditTaskSupp';
import EditUsersInTask from '~/pages/components/common/planning/EditUsersInTask';
import { exportExcel } from '~/pages/components/common/planning/exportExcel';
import { getUsersByFunctionTypeForTasksExt } from '~/pages/components/common/utils-table/utils';
import {
  SDevisTh,
  STaskTd,
  SDevisTableHead,
  SDevisTable,
  STaskDaysTh,
  SDivCheckRetroInPl,
  SDivCheckPlanning,
  STableTr,
} from '~/pages/components/styles/styles';
import AvatarList from '~/pages/components/ui/AvatarList';

import CalendarPagination from '~/pages/components/ui/CalendarPagination';
import { TPeriod, TUsersByFunctionType } from '~/pages/types/types';
import {
  calcRetroPlanningByPlanning,
  compareByOrder,
  getHoursAndMinutesStrByDuration,
  hasPeriodsCross,
  isDatesEqual,
  isDatesLessOrEqual,
  isDatesMoreOrEqual,
} from '~/utils/utils/utils';

type IProps = {
  isPrinting: boolean;
  changeIsPrinting: (value: boolean) => void;
  endProjectDate?: Date;
};

function TasksPlanningExtTable({
  isPrinting,
  changeIsPrinting,
  endProjectDate,
}: IProps) {
  const params = useParams();
  const { id } = params;
  const { i18n } = useLingui();
  const { currentProject, setCurrentDevisProd, currentDevisProd } =
    useDataUserStore();
  const [openEditUsers, setOpenEditUsers] = useState<boolean>(false);
  const [openEditAllUsers, setOpenEditAllUsers] = useState<boolean>(false);
  const [openDeleteTaskSupp, setOpenDeleteTaskSupp] = useState<boolean>(false);
  const [openEditTaskSupp, setOpenEditTaskSupp] = useState<boolean>(false);
  const [usersByFunctionType, setUsersByFunctionType] = useState<
    TUsersByFunctionType[]
  >([]);
  const [usersWithTimeByFunctionType, setUsersWithTimesByFunctionType] =
    useState<TUsersByFunctionType[]>([]);
  const [planning, setPlanning] = useState<StoredPlanning>({
    tasks: [],
    tasksSupp: [],
    calendar: [],
  });

  const [planningPrinting, setPlanningPrinting] = useState<StoredPlanning>({
    tasks: [],
    tasksSupp: [],
    calendar: [],
  });

  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [skipDatesQuery, setSkipDatesQuery] = useState<boolean>(true);
  const [skipAllQuery, setSkipAllQuery] = useState<boolean>(true);

  const take = 30;

  const tableDivRef = useRef<HTMLDivElement>(null);

  const [isAtLeftEnd, setIsAtLeftEnd] = useState(false);
  const [isAtRightEnd, setIsAtRightEnd] = useState(false);

  const [selectedTask, setSelectedTask] = useState<
    StoredTaskInfoForPlanning | undefined
  >(undefined);

  const [period, setPeriod] = useState<TPeriod>({
    beginCell: undefined,
    endCell: undefined,
  });

  useEffect(() => {
    if (!isAtRightEnd) return;
    if (isAll()) return;
    // ça fonctionne bien avec le scroll, mais mieux tester pour les cdp si c'est pratique
    // décommenter si on souhaite scroll
    //   handleNextSkip();
  }, [isAtRightEnd]);

  useEffect(() => {
    if (skip === 0) return;
    if (isStart()) return;
    // ça fonctionne bien avec le scroll, mais mieux tester pour les cdp si c'est pratique
    // décommenter si on souhaite scroll
    //   prevInPlanning();
  }, [isAtLeftEnd]);

  useEffect(() => {
    const handleScroll = () => {
      if (tableDivRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = tableDivRef.current;

        setIsAtLeftEnd(scrollLeft === 0);
        setIsAtRightEnd(scrollLeft + clientWidth >= scrollWidth - 1);
      }
    };

    const table = tableDivRef.current;
    if (table) {
      table.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const { refetch: fetchPeriodPlanningByStartDate } = useQuery(
    GQL_GET_PERIOD_PLANNING_BY_START_DATE,
    {
      variables: {
        filterDatesTaskPlanningInput: {
          id: Number(id),
          startDate: planningPrinting.calendar[0]
            ? planningPrinting.calendar[0].date
            : Date.now(),
          take: planningPrinting.calendar.length || take,
        },
      },
      skip: skipDatesQuery || planningPrinting.calendar.length === 0,
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setPlanningPrinting((old) => {
          return {
            ...old,
            tasks: data.getPeriodTaskPlanningByStartDate.tasks,
            tasksSupp: data.getPeriodTaskPlanningByStartDate.tasksSupp,
          };
        });
      },
      onError: (e) => {
        console.log('erreur dans GQL_GET_PERIOD_PLANNING_BY_START_DATE', e);
      },
    }
  );

  const { refetch: fetchAllPeriodPlanning } = useQuery(
    GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID,
    {
      variables: {
        filterTaskPlanningInput: {
          id: Number(id),
          skip: 0,
          take: planning.calendar.length > 0 ? planning.calendar.length : take,
        },
      },
      skip: skipAllQuery,
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setPlanning((old) => {
          return {
            ...old,
            tasks: data.getPeriodTaskPlanningByProjectId.tasks,
            tasksSupp: data.getPeriodTaskPlanningByProjectId.tasksSupp,
          };
        });

        const users: {
          usersWithoutTime: TUsersByFunctionType[];
          usersWithTime: TUsersByFunctionType[];
        } = getUsersByFunctionTypeForTasksExt(
          data.getPeriodTaskPlanningByProjectId.tasks
        );

        setUsersByFunctionType(users.usersWithoutTime);
        setUsersWithTimesByFunctionType(users.usersWithTime);
      },
      onError: (e) => {
        console.log('erreur dans GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID', e);
      },
    }
  );

  const { refetch: fetchPeriodPlanningByProjectId, loading: isLoading } =
    useQuery(GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID, {
      variables: {
        filterTaskPlanningInput: {
          id: Number(id),
          skip: skip,
          take: take,
        },
      },
      //skip: take === 0,
      client: authClient,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setPlanning((old) => {
          // update tasks
          const newTask = old.tasks.map((t) => {
            const corTask = data.getPeriodTaskPlanningByProjectId.tasks.find(
              (cT) => cT.id === t.id
            );
            return {
              ...t,
              calendar: corTask
                ? [...t.calendar, ...corTask.calendar]
                : t.calendar,
            };
          });

          // update tasksSupp
          const newTaskSupp = old.tasksSupp.map((t) => {
            const corTaskSupp =
              data.getPeriodTaskPlanningByProjectId.tasksSupp.find(
                (cT) => cT.id === t.id
              );
            return {
              ...t,
              calendar: corTaskSupp
                ? [...t.calendar, ...corTaskSupp.calendar]
                : t.calendar,
            };
          });
          return {
            calendar: [
              ...old.calendar,
              ...data.getPeriodTaskPlanningByProjectId.calendar,
            ],
            tasks:
              skip === 0
                ? data.getPeriodTaskPlanningByProjectId.tasks
                : newTask,
            tasksSupp:
              skip === 0
                ? data.getPeriodTaskPlanningByProjectId.tasks
                : newTaskSupp,
          };
        });

        // PlanningPrinting (que 2 tranches de 30 (take) éléments chaqune)
        setPlanningPrinting((old) => {
          // update tasks
          const newTask = old.tasks.map((t) => {
            const corTask = data.getPeriodTaskPlanningByProjectId.tasks.find(
              (cT) => cT.id === t.id
            );
            return {
              ...t,
              calendar: corTask
                ? old.calendar.length <= take
                  ? [...t.calendar, ...corTask.calendar]
                  : [...t.calendar.slice(take), ...corTask.calendar]
                : t.calendar,
            };
          });

          // update tasksSupp
          const newTaskSupp = old.tasksSupp.map((t) => {
            const corTaskSupp =
              data.getPeriodTaskPlanningByProjectId.tasksSupp.find(
                (cT) => cT.id === t.id
              );
            return {
              ...t,
              calendar: corTaskSupp
                ? old.calendar.length <= take
                  ? [...t.calendar, ...corTaskSupp.calendar]
                  : [...t.calendar.slice(take), ...corTaskSupp.calendar]
                : t.calendar,
            };
          });
          return {
            calendar:
              old.calendar.length <= take
                ? [
                    ...old.calendar,
                    ...data.getPeriodTaskPlanningByProjectId.calendar,
                  ]
                : [
                    ...old.calendar.slice(take),
                    ...data.getPeriodTaskPlanningByProjectId.calendar,
                  ],
            tasks:
              skip === 0
                ? data.getPeriodTaskPlanningByProjectId.tasks
                : newTask,
            tasksSupp:
              skip === 0
                ? data.getPeriodTaskPlanningByProjectId.tasksSupp
                : newTaskSupp,
          };
        });

        if (skip === 0) {
          const users: {
            usersWithoutTime: TUsersByFunctionType[];
            usersWithTime: TUsersByFunctionType[];
          } = getUsersByFunctionTypeForTasksExt(
            data.getPeriodTaskPlanningByProjectId.tasks
          );

          setUsersByFunctionType(users.usersWithoutTime);
          setUsersWithTimesByFunctionType(users.usersWithTime);
        }
      },
      onError: (e) => {
        console.log('erreur dans GQL_GET_PERIOD_PLANNING_BY_PROJECT_ID', e);
      },
    });

  function prevInPlanning() {
    if (
      planningPrinting.calendar.length === 0 ||
      planning.calendar.length === 0
    )
      return;

    if (planningPrinting.calendar.length <= take) return;

    const firstPrintingDate = planningPrinting.calendar[0].date as Date;
    const firstDate = planning.calendar[0].date;

    if (isDatesEqual(firstDate as Date, firstPrintingDate)) {
      return;
    }

    setPlanningPrinting((old) => {
      const indInPlanningCalendar = planning.calendar.findIndex((el) =>
        isDatesEqual(el.date as Date, firstPrintingDate as Date)
      );

      const addElements = planning.calendar.slice(
        indInPlanningCalendar - take,
        indInPlanningCalendar
      );

      const newTasks = old.tasks.map((t) => {
        const corTask = planning.tasks.find((cT) => cT.id === t.id);
        const newAdd = corTask
          ? corTask.calendar.slice(
              indInPlanningCalendar - take,
              indInPlanningCalendar
            )
          : [];

        return {
          ...t,
          calendar: [...newAdd, ...t.calendar.slice(0, -take)],
        };
      });

      const newTasksSupp = old.tasks.map((t) => {
        const corTaskSupp = planning.tasksSupp.find((cT) => cT.id === t.id);
        const newAddSupp = corTaskSupp
          ? corTaskSupp.calendar.slice(
              indInPlanningCalendar - take,
              indInPlanningCalendar
            )
          : [];

        return {
          ...t,
          calendar: [...newAddSupp, ...t.calendar.slice(0, -take)],
        };
      });

      const newCalendar = [...addElements, ...old.calendar.slice(0, -take)];

      return {
        tasks: newTasks,
        tasksSupp: newTasksSupp,
        calendar: newCalendar,
      };
    });
  }

  function nextInPlanning() {
    // trouver les 30(take) suivant de cette date in Planning
    setPlanningPrinting((old) => {
      const lastPrintingDate = old.calendar[old.calendar.length - 1].date;
      const indInPlanningCalendar = planning.calendar.findIndex((el) =>
        isDatesEqual(el.date as Date, lastPrintingDate as Date)
      );

      const addElements = planning.calendar.slice(
        indInPlanningCalendar + 1,
        indInPlanningCalendar + take + 1
      );

      const newTasks = old.tasks.map((t) => {
        const corTask = planning.tasks.find((cT) => cT.id === t.id);
        const newAdd = corTask
          ? corTask.calendar.slice(
              indInPlanningCalendar + 1,
              indInPlanningCalendar + take + 1
            )
          : [];

        return {
          ...t,
          calendar: [...t.calendar.slice(take), ...newAdd],
        };
      });

      const newTasksSupp = old.tasks.map((t) => {
        const corTaskSupp = planning.tasksSupp.find((cT) => cT.id === t.id);
        const newAddSupp = corTaskSupp
          ? corTaskSupp.calendar.slice(
              indInPlanningCalendar + 1,
              indInPlanningCalendar + take + 1
            )
          : [];

        return {
          ...t,
          calendar: [...t.calendar.slice(take), ...newAddSupp],
        };
      });

      const newCalendar = [...old.calendar.slice(take), ...addElements];

      return {
        tasks: newTasks,
        tasksSupp: newTasksSupp,
        calendar: newCalendar,
      };
    });
  }

  const [createOrUpdateUsersByFunctionType] = useMutation(
    GQL_CREATE_OR_UPDATE_USERS_BY_FUNCTION_TYPE,
    {
      client: authClient,
      fetchPolicy: 'no-cache',
    }
  );

  const [createOrUpdateTaskPlanning] = useMutation(
    GQL_CREATE_OR_UPDATE_TASK_PLANNING,
    {
      client: authClient,
      fetchPolicy: 'no-cache',
    }
  );

  const [updateTaskPlanning] = useMutation(GQL_UPDATE_TASK_PLANNING, {
    client: authClient,
    fetchPolicy: 'no-cache',
  });

  async function getPeriodPlanningByProjectId() {
    await fetchPeriodPlanningByProjectId();
  }

  async function getPeriodPlanningByStartDate() {
    setSkipDatesQuery(false);
    await fetchPeriodPlanningByStartDate();
    setSkipDatesQuery(true);
  }

  async function getAllPeriodPlanning() {
    setSkipAllQuery(false);
    await fetchAllPeriodPlanning();
    setSkipAllQuery(true);
  }

  useEffect(() => {
    void getPeriodPlanningByProjectId();
  }, [skip]);

  function handleNextSkip() {
    if (skip === 0) {
      setSkip((old) => old + take);
      return;
    }

    if (
      isDatesEqual(
        planning.calendar[planning.calendar.length - 1].date as Date,
        planningPrinting.calendar[planningPrinting.calendar.length - 1]
          .date as Date
      )
    ) {
      setSkip((old) => old + take);
    } else {
      nextInPlanning();
    }
  }

  useEffect(() => {
    if (isPrinting) {
      const shortName = currentProject?.name
        ? currentProject.name.length > 10
          ? currentProject.name.slice(0, 10)
          : currentProject.name
        : '';
      const fileName = currentProject
        ? `${shortName}-planning-${new Date().toLocaleDateString()}`
        : `planning-${new Date().toLocaleDateString()}`;

      //print(fileName);
      const mappedPlanning = calcRetroPlanningByPlanning(planning);

      void exportExcel({
        tasks: mappedPlanning.tasks,
        tasksSupp: mappedPlanning.tasksSupp,
        calendar: mappedPlanning.calendar,
        fileName: fileName,
      });
      //printExcel({ tasks: tasks, tasksSupp: tasksSupp, calendar: calendar });
      changeIsPrinting(false);
    }
  }, [isPrinting]);

  // function print(fileName: string) {
  //   // void exportComponentAsPDF(ref, {
  //   //   fileName: 'retro-planning.pdf',
  //   //   pdfOptions: { orientation: 'l' },
  //   // });
  //   void exportComponentAsJPEG(ref, {
  //     fileName: `${fileName}.jpg`,
  //   });
  // }

  async function handleCellClick(
    e: React.MouseEvent<HTMLTableDataCellElement>,
    itemId: number,
    indInCalendar: number,
    dateInCalendar: Date,
    supp: boolean
  ) {
    e.stopPropagation();
    if (!currentProject) return;
    if (e.shiftKey) {
      if (!period.beginCell) {
        // click pour beginPeriod
        setPeriod({
          beginCell: {
            itemId: itemId,
            indexInCalendar: indInCalendar,
            dateInCalendar: dateInCalendar,
          },
          endCell: undefined,
        });
      } else {
        // click pour endPeriod
        if (!period.beginCell.dateInCalendar) return;
        const start: Date = period.beginCell.dateInCalendar;
        if (
          period.beginCell.itemId === itemId &&
          period.beginCell.indexInCalendar === indInCalendar
        ) {
          // click sur la même case pour enlever la selection de beginCell
          setPeriod({
            beginCell: undefined,
            endCell: undefined,
          });
        } else {
          // contrôle si period est valid
          if (
            period.beginCell.itemId === itemId &&
            period.beginCell.indexInCalendar < indInCalendar
          ) {
            //period est valid
            // puis si c'est la même que dans db => supprimer
            const indItem = (
              supp ? planning.tasksSupp : planning.tasks
            ).findIndex((el) => el.id === itemId);

            if (indItem !== -1) {
              const datesChecked = supp
                ? planningPrinting.tasksSupp[indItem].calendar.filter(
                    (c) => c.checked
                  )
                : planningPrinting.tasks[indItem].calendar.filter(
                    (c) => c.checked
                  );
              if (
                datesChecked.length > 0 &&
                hasPeriodsCross(
                  datesChecked[0].date as Date,
                  datesChecked[datesChecked.length - 1].date as Date,
                  period.beginCell.dateInCalendar,
                  dateInCalendar
                ) === -1
              ) {
                // si le periode choisi correspond au taskPlanning dans db => supprimer les anciens taskPlanning

                const { data: _data } = await createOrUpdateTaskPlanning({
                  variables: {
                    projectId: currentProject.id,
                    taskId: itemId,
                    dates: [],
                  },
                });
                if (_data) {
                  setCurrentDevisProd({
                    ..._data.createOrUpdateTaskPlanning,
                    tasks:
                      _data.createOrUpdateTaskPlanning.tasks?.sort(
                        compareByOrder
                      ),
                  });
                  setPeriod({
                    beginCell: undefined,
                    endCell: undefined,
                  });
                  void getPeriodPlanningByStartDate();
                  void getAllPeriodPlanning();
                }
              } else {
                // period correct mais ne correspond pas au period dans la db

                // const _dates = (
                //   supp
                //     ? planningPrinting.tasksSupp[indItem].calendar
                //     : planningPrinting.tasks[indItem].calendar
                // )
                //   .filter(
                //     (element) =>
                //       element.selectible &&
                //       isDatesMoreOrEqual(element.date as Date, start) &&
                //       isDatesLessOrEqual(element.date as Date, dateInCalendar)
                //   )
                //   .map((element) => element.date);

                const _datesNew: {
                  date: Date;
                  checked: boolean;
                  id?: number;
                }[] = (
                  supp
                    ? planningPrinting.tasksSupp[indItem].calendar
                    : planningPrinting.tasks[indItem].calendar
                )
                  .filter(
                    (element) =>
                      element.selectible &&
                      isDatesMoreOrEqual(element.date as Date, start) &&
                      isDatesLessOrEqual(element.date as Date, dateInCalendar)
                  )
                  .map((element) => {
                    return {
                      date: element.date as Date,
                      checked: !element.checked,
                      ...(element.id && { id: element.id }),
                    };
                  });

                console.log('_datesNew', _datesNew);

                void handleStatePeriodChange(itemId, _datesNew);
                setPeriod({
                  beginCell: undefined,
                  endCell: undefined,
                });
              }
            }
          } else {
            setPeriod({
              beginCell: undefined,
              endCell: undefined,
            });
          }
        }
      }
    } else {
      void changeCell(itemId, indInCalendar);
    }
  }

  async function handleStatePeriodChange(
    itemId: number,
    newDates: { date: Date; checked: boolean; id?: number }[]
  ) {
    if (!currentProject) return;
    const items: StoredTaskInfoForPlanning[] = JSON.parse(
      JSON.stringify(planningPrinting.tasks.concat(planningPrinting.tasksSupp))
    ).map((el: any) => el as StoredTaskInfoForPlanning);

    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemId === -1) return;

    console.log('newDates', newDates);

    const { data: _data } = await updateTaskPlanning({
      variables: {
        taskId: items[itemIndex].id,
        dates: newDates,
      },
    });

    if (_data) {
      setCurrentDevisProd({
        ..._data.updateTaskPlanning,
        tasks: _data.updateTaskPlanning.tasks?.sort(compareByOrder),
      });
      void getPeriodPlanningByStartDate();
      void getAllPeriodPlanning();
    }

    // const items: StoredTaskInfoForPlanning[] = JSON.parse(
    //   JSON.stringify(planningPrinting.tasks.concat(planningPrinting.tasksSupp))
    // ).map((el: any) => el as StoredTaskInfoForPlanning);

    // const itemIndex = items.findIndex((item) => item.id === itemId);
    // if (itemId === -1) return;

    // const checkedDates: { date: Date; id?: number | undefined }[] = items[
    //   itemIndex
    // ].taskPlanning
    //   //.filter((el) => el.duration > 0)
    //   .map((d) => {
    //     return {
    //       date: d.date as Date,
    //       id: d.id,
    //     };
    //   });

    // for (const nD of dates) {
    //   const ind = checkedDates.findIndex((el) => isDatesEqual(el.date, nD)); //  new Date(el.date).setHours(8, 0, 0, 0) === new Date(nD).setHours(8, 0, 0, 0)
    //   if (ind !== -1) {
    //     checkedDates.splice(ind, 1);
    //   } else checkedDates.push({ date: nD });
    // }

    // const average = items[itemIndex].duration / checkedDates.length;
    // const _dates: { date: Date; duration: number; id?: number }[] =
    //   checkedDates.map((d) => {
    //     return {
    //       date: d.date,
    //       duration: Math.round(average),
    //       ...(d.id && { id: d.id }),
    //     };
    //   });

    // // TODO
    // const calcDuration = _dates.reduce(function (acc, obj) {
    //   return acc + obj.duration;
    // }, 0);
    // const deltaDuration = calcDuration - items[itemIndex].duration;

    // if (deltaDuration !== 0 && _dates.length > 0) {
    //   _dates[_dates.length - 1].duration =
    //     _dates[_dates.length - 1].duration - deltaDuration;
    // }

    // if (!currentProject?.id) return;
    // const { data: _data } = await createOrUpdateTaskPlanning({
    //   variables: {
    //     projectId: currentProject.id,
    //     taskId: items[itemIndex].id,
    //     dates: _dates,
    //   },
    // });
    // if (_data) {
    //   setCurrentDevisProd({
    //     ..._data.createOrUpdateTaskPlanning,
    //     tasks: _data.createOrUpdateTaskPlanning.tasks?.sort(compareByOrder),
    //   });
    //   void getPeriodPlanningByStartDate();
    //   void getAllPeriodPlanning();
    // }
  }

  async function handleChangeAllUsers(_users: TUsersByFunctionType[]) {
    if (!currentProject?.id) return;

    const { data: _data } = await createOrUpdateUsersByFunctionType({
      variables: {
        projectId: currentProject.id,
        usersByFunctionType: _users,
      },
    });

    if (_data) {
      setCurrentDevisProd({
        ..._data.createOrUpdateUsersInTaskByFunctionType,
        tasks:
          _data.createOrUpdateUsersInTaskByFunctionType.tasks?.sort(
            compareByOrder
          ),
      });
      void getPeriodPlanningByStartDate();
      void getAllPeriodPlanning();
    }
  }

  async function changeCell(itemId: number, calendarIndex: number) {
    const itemsMapped = JSON.parse(
      JSON.stringify(planningPrinting.tasks.concat(planningPrinting.tasksSupp))
    );
    const items: StoredTaskInfoForPlanning[] = itemsMapped.map(
      (el: any) => el as StoredTaskInfoForPlanning
    );

    const itemIndex = items.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      // updateTaskPlanning
      const { data: _data } = await updateTaskPlanning({
        variables: {
          taskId: items[itemIndex].id,
          dates: [
            {
              date: items[itemIndex].calendar[calendarIndex].date,
              checked: !items[itemIndex].calendar[calendarIndex].checked,
            },
          ],
        },
      });

      // ancienne version
      // items[itemIndex].calendar[calendarIndex].checked =
      //   !items[itemIndex].calendar[calendarIndex].checked;

      // const count = items[itemIndex].calendar.filter((el) => el.checked).length;
      // const average = items[itemIndex].duration / count;
      // for (let j = 0; j < items[itemIndex].calendar.length; j++) {
      //   if (items[itemIndex].calendar[j].checked)
      //     items[itemIndex].calendar[j].label = getHoursStrByDuration(
      //       Math.round(average)
      //     );
      // }

      // const _dates = getNewDates(items[itemIndex], count);

      // if (!currentProject?.id) return;

      // const { data: _data } = await createOrUpdateTaskPlanning({
      //   variables: {
      //     projectId: currentProject.id,
      //     taskId: items[itemIndex].id,
      //     dates: _dates,
      //   },
      // });
      if (_data) {
        setCurrentDevisProd({
          ..._data.updateTaskPlanning,
          tasks: _data.updateTaskPlanning.tasks?.sort(compareByOrder),
        });

        void getPeriodPlanningByStartDate();
        void getAllPeriodPlanning();
      }
    }
  }

  function handleAddSuppTask() {
    setOpenAdd(true);
  }

  function isAll() {
    if (endProjectDate && planning.calendar.length > 0) {
      if (
        new Date(
          planning.calendar[planning.calendar.length - 1].date as Date
        ).setHours(8, 0, 0, 0) >= new Date(endProjectDate).setHours(8, 0, 0, 0)
      )
        return true;
    }
    return false;
  }

  function isStart() {
    if (planning.calendar[0] && planningPrinting.calendar[0]) {
      if (
        new Date(planning.calendar[0].date as Date).setHours(8, 0, 0, 0) ===
        new Date(planningPrinting.calendar[0].date as Date).setHours(8, 0, 0, 0)
      )
        return true;
    }
    return false;
  }

  function handleUpdateUsersInTaskInPlanning(
    taskId: number,
    users: StoredUsersInfoForDevis[] | undefined,
    isInternTask: boolean
  ) {
    setPlanningPrinting((old) => {
      const ind = old.tasks.findIndex((t) => t.id === taskId);
      const newUserList = users ? [...users] : [];

      if (!isInternTask) {
        if (JSON.stringify(old.tasks[ind].user) === JSON.stringify(newUserList))
          return old;
        const newTasks = old.tasks.map((t, i) =>
          i === ind ? { ...t, user: newUserList } : t
        );

        return {
          ...old,
          tasks: newTasks,
        };
      } else {
        if (
          JSON.stringify(old.tasksSupp[ind].user) ===
          JSON.stringify(newUserList)
        )
          return old;
        const newTasksSupp = old.tasksSupp.map((t, i) =>
          i === ind ? { ...t, user: newUserList } : t
        );

        return {
          ...old,
          tasksSupp: newTasksSupp,
        };
      }
    });
  }

  return (
    <Container>
      <STableContainer ref={tableDivRef}>
        {isLoading && skip === 0 ? (
          <p>{i18n._('common.loading')} ...</p>
        ) : (
          <>
            <SDevisTable>
              <SDevisTableHead>
                <tr>
                  <SDevisTh $textAlign="center" $minWidth="180px">
                    {i18n._('common.task.name')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('common.function.type')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('common.duration')}
                  </SDevisTh>
                  <SDevisTh $textAlign="center">
                    {i18n._('form.edit.users')}
                    <Tooltip
                      title={i18n._('common.edit.users.by.function.type')}
                    >
                      <Button
                        shape="circle"
                        onClick={() => {
                          setOpenEditAllUsers(true);
                        }}
                        icon={<UsergroupAddOutlined />}
                      />
                    </Tooltip>
                  </SDevisTh>
                  {planningPrinting.calendar.map((el, index) => (
                    <STaskDaysTh
                      $height="95px"
                      $width="45px"
                      $textAlign="center"
                      key={index}
                    >
                      <SDivRotate>
                        {new Date(el.date as Date).toLocaleDateString()}
                      </SDivRotate>
                    </STaskDaysTh>
                  ))}
                </tr>
              </SDevisTableHead>

              <tbody>
                {planningPrinting.tasks.length > 0 &&
                  planningPrinting.tasks.map((item, indexInList) => (
                    <STableTr key={indexInList}>
                      <STaskTd $padding="0 3px">{item.name}</STaskTd>
                      <STaskTd $padding="0 3px">
                        {i18n._(item.functionType)}
                      </STaskTd>
                      <STaskTd $padding="0 3px" $textAlign="right">
                        {item.functionType === FunctionType.CLIENT
                          ? item.durationClientDays + 'j'
                          : getHoursAndMinutesStrByDuration(item.duration)}
                      </STaskTd>
                      <STaskTd $textAlign="right">
                        <SDiv>
                          <AvatarList users={item.user}></AvatarList>
                          {item.functionType !== FunctionType.CLIENT &&
                            !item.calculatedAsAmount && (
                              <Tooltip title={i18n._('common.edit.users')}>
                                <Button
                                  shape="circle"
                                  onClick={() => {
                                    setSelectedTask(item);
                                    setOpenEditUsers(true);
                                  }}
                                  icon={<EditOutlined />}
                                />
                              </Tooltip>
                            )}
                        </SDiv>
                      </STaskTd>

                      {planningPrinting.tasks[indexInList]?.calendar?.map(
                        (itC, ind) => (
                          <Tooltip
                            key={ind}
                            title={
                              item.name +
                              ' / ' +
                              new Date(itC.date as Date).toLocaleDateString()
                            }
                          >
                            <STaskTd
                              onClick={(e) => {
                                void handleCellClick(
                                  e,
                                  item.id,
                                  ind,
                                  itC.date as Date,
                                  false
                                );
                              }}
                              key={ind}
                              className={
                                !itC.selectible
                                  ? 'notselectible'
                                  : period.beginCell?.itemId === item.id &&
                                      period.beginCell.indexInCalendar === ind
                                    ? 'period'
                                    : period.endCell?.itemId === item.id &&
                                        period.endCell.indexInCalendar === ind
                                      ? 'end'
                                      : ''
                              }
                            >
                              {itC.retroChecked ? (
                                <SDivCheckRetroInPl></SDivCheckRetroInPl>
                              ) : (
                                <></>
                              )}
                              {itC.checked && (
                                <SDivCheckPlanning
                                  className={
                                    item.functionType === FunctionType.CLIENT
                                      ? 'client'
                                      : ''
                                  }
                                >
                                  <SSpanLabel>
                                    {item.functionType !== FunctionType.CLIENT
                                      ? itC.label
                                      : ''}
                                  </SSpanLabel>
                                </SDivCheckPlanning>
                              )}
                            </STaskTd>
                          </Tooltip>
                        )
                      )}
                    </STableTr>
                  ))}
                <tr style={{ height: '20px' }}></tr>
                {planningPrinting.tasksSupp.length > 0 &&
                  planningPrinting.tasksSupp.map((item, indexInList) => (
                    <STableTr key={indexInList}>
                      <STaskTd $padding="0 3px">
                        <SDiv>
                          {item.name}
                          <Tooltip title={i18n._('table.task.edit')}>
                            <Button
                              shape="circle"
                              onClick={() => {
                                setSelectedTask(item);
                                setOpenEditTaskSupp(true);
                              }}
                              icon={<EditOutlined />}
                            />
                          </Tooltip>
                          {item.taskPlanning.length === 0 && (
                            <>
                              <Tooltip title={i18n._('table.task.delete')}>
                                <Button
                                  shape="circle"
                                  onClick={() => {
                                    setSelectedTask(item);
                                    setOpenDeleteTaskSupp(true);
                                  }}
                                  icon={<DeleteOutlined />}
                                />
                              </Tooltip>
                            </>
                          )}
                        </SDiv>
                      </STaskTd>
                      <STaskTd $padding="0 3px">
                        {i18n._(item.functionType)}
                      </STaskTd>
                      <STaskTd $padding="0 3px" $textAlign="right">
                        {item.duration > 0
                          ? getHoursAndMinutesStrByDuration(item.duration)
                          : item.amount.toFixed(2) + '€'}
                      </STaskTd>
                      <STaskTd $textAlign="right">
                        <SDiv>
                          <AvatarList users={item.user}></AvatarList>
                          {(item.functionType !== FunctionType.CLIENT ||
                            !item.calculatedAsAmount) && (
                            <Tooltip title={i18n._('common.edit.users')}>
                              <Button
                                shape="circle"
                                onClick={() => {
                                  setSelectedTask(item);
                                  setOpenEditUsers(true);
                                }}
                                icon={<EditOutlined />}
                              />
                            </Tooltip>
                          )}
                        </SDiv>
                      </STaskTd>

                      {planningPrinting.tasksSupp[indexInList]?.calendar?.map(
                        (itC, ind) => (
                          <Tooltip
                            key={ind}
                            title={
                              item.name +
                              ' / ' +
                              new Date(itC.date as Date).toLocaleDateString()
                            }
                          >
                            <STaskTd
                              onClick={(e) => {
                                if (item.duration > 0)
                                  void handleCellClick(
                                    e,
                                    item.id,
                                    ind,
                                    itC.date as Date,
                                    true
                                  );
                              }}
                              key={ind}
                              className={
                                !itC.selectible
                                  ? 'notselectible'
                                  : period.beginCell?.itemId === item.id &&
                                      period.beginCell.indexInCalendar === ind
                                    ? 'period'
                                    : period.endCell?.itemId === item.id &&
                                        period.endCell.indexInCalendar === ind
                                      ? 'end'
                                      : ''
                              }
                            >
                              {itC.retroChecked ? (
                                <SDivCheckRetroInPl></SDivCheckRetroInPl>
                              ) : (
                                <></>
                              )}
                              {itC.checked && (
                                <SDivCheckPlanning
                                  className={
                                    item.functionType === FunctionType.CLIENT
                                      ? 'client'
                                      : ''
                                  }
                                >
                                  <SSpanLabel>
                                    {item.functionType !== FunctionType.CLIENT
                                      ? itC.label
                                      : ''}
                                  </SSpanLabel>
                                </SDivCheckPlanning>
                              )}
                            </STaskTd>
                          </Tooltip>
                        )
                      )}
                    </STableTr>
                  ))}
              </tbody>
            </SDevisTable>
          </>
        )}
      </STableContainer>
      <>
        <Button
          style={{ width: '300px' }}
          disabled={!currentDevisProd}
          onClick={handleAddSuppTask}
          type="primary"
        >
          <Trans id="common.add.task.supp" />
        </Button>
      </>

      {openEditAllUsers && (
        <EditAllUsers
          onChangeUsers={handleChangeAllUsers}
          usersByFunctionType={usersByFunctionType}
          usersWithTimeByFunctionType={usersWithTimeByFunctionType}
          title={i18n._('task.edit.all.users')}
          isOpen={openEditAllUsers}
          close={() => setOpenEditAllUsers(false)}
          confirm={() => {
            setOpenEditAllUsers(false);
          }}
        />
      )}

      {selectedTask?.id && (
        <EditUsersInTask
          taskId={selectedTask.id}
          users={selectedTask.user}
          title={i18n._('task.edit.users')}
          isOpen={openEditUsers}
          close={() => setOpenEditUsers(false)}
          confirm={(taskId, users, isInternTask) => {
            setOpenEditUsers(false);
            handleUpdateUsersInTaskInPlanning(taskId, users, isInternTask);
          }}
        />
      )}
      {selectedTask && (
        <DeleteTaskSupp
          task={selectedTask}
          title={i18n._('table.task.delete')}
          isOpen={openDeleteTaskSupp}
          close={() => setOpenDeleteTaskSupp(false)}
          confirm={() => {
            setOpenDeleteTaskSupp(false);
            void getPeriodPlanningByStartDate();
            void getAllPeriodPlanning();
          }}
        />
      )}
      {selectedTask && (
        <EditTaskSupp
          task={selectedTask}
          title={i18n._('table.task.edit')}
          isOpen={openEditTaskSupp}
          close={() => setOpenEditTaskSupp(false)}
          confirm={() => {
            setOpenEditTaskSupp(false);
            void getPeriodPlanningByStartDate();
            void getAllPeriodPlanning();
          }}
        />
      )}
      {currentProject && (
        <AddTaskSupp
          projectId={currentProject.id}
          title={i18n._('task.supp.add')}
          isOpen={openAdd}
          close={() => setOpenAdd(false)}
          confirm={() => {
            setOpenAdd(false);

            void getPeriodPlanningByStartDate();
            void getAllPeriodPlanning();
          }}
        />
      )}

      <CalendarPagination
        isAll={isAll()}
        isLoading={isLoading}
        isStart={isStart()}
        onNext={handleNextSkip}
        onPrev={prevInPlanning}
      />
    </Container>
  );
}

export default TasksPlanningExtTable;

const STableContainer = styled.div`
  width: max-content;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;
const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SDivRotate = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  left: -22px;
  bottom: 35px;
`;

const SSpanLabel = styled.span`
  font-size: 10px;
`;

const SDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
`;
