import { StoredPoste } from '~/gql/poste/poste';
import { StoredTaskInfo } from '~/gql/task/task';
import {
  StoredPlanning,
  StoredTaskInfoForPlanning,
} from '~/gql/task-planning/task-planning';
import {
  DevisInitRowExtended,
  DevisProdRow,
  TPeriodDates,
  // TaskInfoWithCalender,
} from '~/pages/types/types';

function mappedStoredTasks(
  tasks: StoredTaskInfoForPlanning[]
): StoredTaskInfoForPlanning[] {
  return tasks.map((task) => {
    const calendar = [...task.calendar];

    const firstCheckedIndex = calendar.findIndex((c) => c.checked);
    const lastCheckedIndex =
      calendar.length - 1 - [...calendar].reverse().findIndex((c) => c.checked);

    if (
      firstCheckedIndex !== -1 &&
      lastCheckedIndex !== -1 &&
      firstCheckedIndex <= lastCheckedIndex
    ) {
      for (let i = firstCheckedIndex; i <= lastCheckedIndex; i++) {
        calendar[i].checked = true;
      }
    }

    return {
      ...task,
      calendar,
    };
  });
}
export function calcRetroPlanningByPlanning(
  planning: StoredPlanning
): StoredPlanning {
  const _planning = JSON.parse(JSON.stringify(planning));
  const _tasks = mappedStoredTasks(_planning.tasks);
  const _tasksSupp = mappedStoredTasks(_planning.tasksSupp);

  return {
    tasks: _tasks,
    tasksSupp: _tasksSupp,
    calendar: planning.calendar,
  };
}

export function getHoursAndMinutesByDuration(duration: number): {
  h: number;
  m: number;
} {
  const _hours = Math.floor(duration / 60);
  const _minutes = duration % 60;

  return {
    h: _hours,
    m: _minutes,
  };
}

export function getHoursAndMinutesStrByDuration(
  duration: number,
  withoutZero?: boolean
): string {
  let _hours = Math.floor(duration / 60).toString();
  let _minutes = (duration % 60).toString();
  if (_minutes.length === 1) _minutes = `0${_minutes}`;
  if (_hours.length === 1) _hours = `0${_hours}`;

  return withoutZero && duration === 0 ? ' - ' : `${_hours}:${_minutes}`;
}

export function getHoursAndMinutesStrByDurationForTimes(
  duration: number
): string {
  if (duration === 0) return '';
  if (duration > 0) {
    let _hours = Math.floor(duration / 60).toString();
    let _minutes = (duration % 60).toString();
    if (_minutes.length === 1) _minutes = `0${_minutes}`;
    if (_hours.length === 1) _hours = `0${_hours}`;
    return `${_hours} h ${_minutes}`;
  } else {
    const durationPos = duration * -1;

    let _hours = Math.floor(durationPos / 60).toString();
    let _minutes = (durationPos % 60).toString();
    if (_minutes.length === 1) _minutes = `0${_minutes}`;
    if (_hours.length === 1) _hours = `0${_hours}`;
    return `- ${_hours} h ${_minutes}`;
  }
}

export function getHoursStrByDuration(duration: number): string {
  const _hours = Math.floor(duration / 60);
  let _minutes = (duration % 60).toString();
  if (_minutes.length === 1) _minutes = `0${_minutes}`;

  return `${_hours}h${_minutes}`;
}

export function compareByOrder(
  a: DevisInitRowExtended | StoredTaskInfo | DevisProdRow,
  b: DevisInitRowExtended | StoredTaskInfo | DevisProdRow
) {
  if (a.order !== undefined && b.order !== undefined) {
    return a.order - b.order;
  }

  return 0;
}

export function compareByDate(a: Date, b: Date) {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) ===
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return 0;
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) >
    new Date(b as Date).setHours(8, 0, 0, 0)
  ) {
    return 1;
  } else {
    return -1;
  }
}

export function isDatesEqual(a: Date, b: Date): boolean {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) ===
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return true;
  return false;
}

export function isDatesMoreOrEqual(a: Date, b: Date): boolean {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) >=
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return true;
  return false;
}

export function isDatesMore(a: Date, b: Date): boolean {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) >
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return true;
  return false;
}

export function isDatesLessOrEqual(a: Date, b: Date): boolean {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) <=
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return true;
  return false;
}

export function isDatesLess(a: Date, b: Date): boolean {
  if (
    new Date(a as Date).setHours(8, 0, 0, 0) <
    new Date(b as Date).setHours(8, 0, 0, 0)
  )
    return true;
  return false;
}

export function compareString(a: StoredPoste, b: StoredPoste) {
  if (a.name && b.name && a.name < b.name) {
    return -1;
  }
  if (a.name && b.name && a.name > b.name) {
    return 1;
  }

  return 0;
}

export function hasPeriodsCross(
  startDate1: Date,
  endDate1: Date,
  startDate2: Date,
  endDate2: Date
): number {
  const begin1 = new Date(startDate1).setHours(8, 0, 0, 0);
  const end1 = new Date(endDate1).setHours(8, 0, 0, 0);
  const begin2 = new Date(startDate2).setHours(8, 0, 0, 0);
  const end2 = new Date(endDate2).setHours(8, 0, 0, 0);

  if (begin1 === begin2 && end1 === end2) {
    // les périodes sont les mêmes
    return -1;
  }
  if ((begin1 <= end2 && begin2 <= end1) || (begin2 <= end1 && begin1 <= end2))
    return 1;
  else return 0;
}

export function isSameDates(date1: Date, date2: Date): boolean {
  const mDate1 = new Date(date1).setHours(8, 0, 0, 0);
  const mDate2 = new Date(date2).setHours(8, 0, 0, 0);
  return mDate1 === mDate2;
}

export function couleurRandomHex() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  const couleurHex =
    '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return couleurHex;
}

// semaine
export function getStartAndEndForWeek(dateInitiale: Date): TPeriodDates {
  const date = new Date(dateInitiale);
  const jourSemaine = date.getDay();
  const debutSemaine = new Date(date);
  debutSemaine.setDate(date.getDate() - jourSemaine + 1);
  debutSemaine.setHours(8, 0, 0, 0);

  const finSemaine = new Date(date);
  finSemaine.setDate(date.getDate() + (7 - jourSemaine));
  finSemaine.setHours(20, 59, 59, 999);

  return {
    start: debutSemaine,
    end: finSemaine,
  };
}

export function getStartAndEndForYear(year: number): TPeriodDates {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);

  return {
    start: startDate,
    end: endDate,
  };
}

export function getPrevWeek(startDate: Date, endDate: Date): TPeriodDates {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startPrevWeek = new Date(start);
  startPrevWeek.setDate(start.getDate() - 7);
  const endPrevWeek = new Date(end);
  endPrevWeek.setDate(end.getDate() - 7);

  return {
    start: startPrevWeek,
    end: endPrevWeek,
  };
}

export function getNextWeek(startDate: Date, endDate: Date): TPeriodDates {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const startNextWeek = new Date(start);
  startNextWeek.setDate(start.getDate() + 7);

  const endNextWeek = new Date(end);
  endNextWeek.setDate(end.getDate() + 7);

  return {
    start: startNextWeek,
    end: endNextWeek,
  };
}

export function getStartAndEndForMonth(dateInitiale: Date): TPeriodDates {
  const date = new Date(dateInitiale);
  const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    start: startMonth,
    end: endMonth,
  };
}

export function getPrevMonth(startDate: Date): TPeriodDates {
  const date = new Date(startDate);
  const startPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const endPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

  return {
    start: startPrevMonth,
    end: endPrevMonth,
  };
}

export function getNextMonth(startDate: Date): TPeriodDates {
  const date = new Date(startDate);

  const startNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const endNextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  return {
    start: startNextMonth,
    end: endNextMonth,
  };
}
