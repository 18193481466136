import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { styled } from 'styled-components';

type IProps = {
  isStart: boolean;
  isAll: boolean;
  isLoading: boolean;
  onNext: () => void;
  onPrev: () => void;
};

function CalendarPagination({
  isAll,
  isStart,
  onNext,
  onPrev,
  isLoading,
}: IProps) {
  return (
    <Container>
      <Button
        shape="circle"
        disabled={isLoading || isStart}
        onClick={(e) => {
          e.stopPropagation();
          onPrev();
        }}
        type="primary"
        icon={<LeftOutlined />}
      />
      <Button
        shape="circle"
        type="primary"
        disabled={isLoading || isAll}
        onClick={(e) => {
          e.stopPropagation();
          onNext();
        }}
        icon={<RightOutlined />}
      />
    </Container>
  );
}

export default CalendarPagination;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 50px 20px 0;

  & > *:first-of-type {
    margin-right: 20px;
  }
`;
